import React from "react"
import Menu from '../components/menu'

const NotFoundPage = () => {
  return (
    <div className="main_wrapper">
      <Menu></Menu>
        <h1>Page not found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  )
}

export default NotFoundPage
