import React from 'react';
import { Link } from "gatsby"

const Menu = ({ content, setClassActive }) => {
  return (
    <div className="row centered">
      <div className="menu">
        <Link
          to={'/drinks'}
          state={{content}}
          className={`${setClassActive === 'drinks' ? "active" : "inactive"}`}
        >
          Drink
        </Link>

        <Link to={'/'} className={`home ${setClassActive === 'home' ? "active" : "inactive"}`}>

        </Link>


        <Link
          to={"/tasting"}
          state={{content}}
          className={`${setClassActive === 'tasting' ? "active" : "inactive"}`}
        >
          Learn
        </Link>
      </div>
    </div>

  )
}

export default Menu;
